@import 'modules/mixin';

.section{
	position: relative;
}
.logo{
	max-width: 296px;
	margin: 30px auto;

	@include MQ(599) {
		max-width: 148px;
	}
}
.commonSection{
	position: relative;
	@include font-size(20px);
	text-align: center;

	@include MQ(599) {
		@include font-size(18px);
	}

	h1{
		@include font-size(24px);
		font-weight: 700;
		padding-bottom: 15px;
		color: #ed5526;
	}

	p{
		font-weight: 300;
		line-height: 1.7;
	}

	li{
		font-weight: 300;
		padding: 8px 0;
		border-bottom: 1px solid #f2f2f2;
	}
}
.aboutText{
	padding: 20px 0 0 0;

	@include MQ(599) {
		padding: 0;
	}
}
.facilitiesTab{
	padding: 40px 0;
}
.contactInfoTab{
	a{
		color: #383838;
		padding-bottom: 2px;
		border-bottom: 1px solid #383838;
		text-decoration: none;

		&:hover{
			border-bottom: 1px solid transparent;
		}
	}
}
.comingsoonTab{
	padding: 50px 0 30px 0;
	@include font-size(20px);
	color:  #383838;
	font-weight: bold;
	text-transform: uppercase;
}
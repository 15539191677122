/*-HTML5 Reset :: style.css---------------------------------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {
  display: block;
}

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

img,
object,
embed {
  max-width: 100%;
}

img {
  /*display:block;*/
  vertical-align: bottom;
}

/* force a vertical scrollbar to prevent a jumpy page */
/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */
}

input[type="radio"] {
  vertical-align: text-bottom;
}

input[type="checkbox"] {
  vertical-align: bottom;
}

.ie7 input[type="checkbox"] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select, input, textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td, td img {
  vertical-align: top;
}

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0;
}

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible;
}

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic;
}

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

li.clearfix {
  display: list-item;
}

/*Hide these rules from IE-mac */
* html .clearfix {
  height: 1%;
}

.clearfix {
  *zoom: 1;
}

/*-End HTML5 Reset :: style.css------------------------------------------------------------------------------------------*/
.hidden, .invisible {
  visibility: hidden;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.boldcase {
  font-weight: 700;
}

.normalcase {
  font-weight: 400;
}

.textdec-underline {
  text-decoration: underline;
}

.textdec-normal, a {
  text-decoration: none;
}

.Text-Idnt {
  text-indent: -99999px;
}

.hidden {
  display: none !important;
}

.visuallyhidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
}

img.floatleft {
  float: left;
  margin: 0 10px 0 0;
}

img.floatright {
  float: right;
  margin: 0 0 0 10px;
}

.floatleft {
  float: left;
}

.floatright {
  float: right;
}

a {
  color: #000;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

a:hover {
  text-decoration: underline;
}

p:last-child {
  padding-bottom: 0 !important;
}

@keyframes smooth-entry {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1.8em);
    -moz-transform: translateY(-1.8em);
    -ms-transform: translateY(-1.8em);
    -o-transform: translateY(-1.8em);
    transform: translateY(-1.8em);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

body {
  background: #fff;
  font: normal 100%/1.5 "Roboto", Arial, Helvetica, sans-serif;
  color: #383838;
  font-weight: 300;
  margin: 0;
  text-align: left;
}

.wrapper {
  position: relative;
  width: 100%;
  max-width: 50em;
  /*800/16*/
  padding: 0 25px;
  margin: 0 auto;
}

@media (max-width: 47.9375em) {
  .wrapper {
    padding: 0 20px;
  }
}

.container {
  background: #fff;
  padding-bottom: 30px;
}

.section {
  position: relative;
}

.logo {
  max-width: 296px;
  margin: 30px auto;
}

@media (max-width: 37.4375em) {
  .logo {
    max-width: 148px;
  }
}

.commonSection {
  position: relative;
  font-size: 20px;
  font-size: 1.25rem;
  text-align: center;
}

@media (max-width: 37.4375em) {
  .commonSection {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.commonSection h1 {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 700;
  padding-bottom: 15px;
  color: #ed5526;
}

.commonSection p {
  font-weight: 300;
  line-height: 1.7;
}

.commonSection li {
  font-weight: 300;
  padding: 8px 0;
  border-bottom: 1px solid #f2f2f2;
}

.aboutText {
  padding: 20px 0 0 0;
}

@media (max-width: 37.4375em) {
  .aboutText {
    padding: 0;
  }
}

.facilitiesTab {
  padding: 40px 0;
}

.contactInfoTab a {
  color: #383838;
  padding-bottom: 2px;
  border-bottom: 1px solid #383838;
  text-decoration: none;
}

.contactInfoTab a:hover {
  border-bottom: 1px solid transparent;
}

.comingsoonTab {
  padding: 50px 0 30px 0;
  font-size: 20px;
  font-size: 1.25rem;
  color: #383838;
  font-weight: bold;
  text-transform: uppercase;
}

*::-moz-selection {
  background: none repeat scroll 0 0 #1777af;
  color: #fafafa;
  text-shadow: none;
}

input:-moz-placeholder {
  font-size: 0.875em;
  line-height: 1.4;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
}

input[type="search"] {
  box-sizing: content-box;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0 none;
  padding: 0;
}

p {
  /*-moz-hyphens: auto;*/
}

@media print {
  * {
    background: none repeat scroll 0 0 transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }
  a, a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: "";
  }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr, img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }
  h2, h3 {
    page-break-after: avoid;
  }
}
